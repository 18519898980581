<template>
  <b-row
    class="match-height"
  >
    <b-col
      cols="12"
      md="4"
    >
      <b-card no-body>
        <b-card-body class="d-flex justify-content-center">
          <product-card
          style="min-width: 280px; max-width: 300px;"
          ></product-card>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col 
      cols="12"
      md="8"
    >
      <b-card>
        <b-card-body style="overflow: auto">
          <div
            v-if="isLoading"
            class="my-3"
          >
            <custom-loading></custom-loading>
          </div>
          <div
            v-else
          >
            <v-grid
              ref="refGrodProductoClass"
              :source="source" 
              :columns="columns" 
              theme="material" 
              resize="true"
              autoSizeColumn = true
              class="grid-container-product-classification"
            ></v-grid>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import ProductCard from './ProductCard.vue'
import { ref, getCurrentInstance } from '@vue/composition-api'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import VGrid from '@revolist/vue-datagrid'
import gql from 'graphql-tag'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardBody,

    ProductCard,
    CustomLoading,
    VGrid,
  },
  setup() {
    const source = ref([])
    const isLoading = ref(true)
    const refGrodProductoClass = ref(null)
    const columns = ref([])

    const vm = getCurrentInstance().proxy

    columns.value = [
      {
        "name": "Nart",
        "prop": "Material",
        "size": 200,
        "autoSize": true,
        "readonly": true,
      },
      {
        "name": "Descripcion",
        "prop": "Descripcion",
        "size": 500,
        "autoSize": true,
        "readonly": true,
      },
    ]

    // subscription
    vm.$apollo.addSmartSubscription('ProductClassification', {
      query: gql`
        subscription ProductClassification {
          Nart_sin_clasificar {
            Material
            Descripcion
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        source.value = data.Nart_sin_clasificar
      },
    })

    return {
      isLoading,
      source,
      refGrodProductoClass,
      columns,

    }
  },
}
</script>

<style lang="scss">
.grid-container-product-classification {
  width: 850px;
  height: 300px;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }
}
</style>