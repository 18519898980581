<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <b-card-body style="overflow: auto">
        <b-row class="mb-1">
          <b-button
          @click="resetFilter"
          variant="primary"
          class="ml-1"
          >
            Reset Filters
          </b-button>

          <b-button
          @click="saveData"
          :disabled="isDisabledSaveData"
          variant="primary"
          class="ml-1"
          >
            Save
          </b-button>
        </b-row>
        <b-row style="padding: 13px">
          <v-grid
            ref="refGridProductMaster"
            :source="source" 
            :columns="columns" 
            theme="material" 
            resize="true"
            autoSizeColumn = true
            range="true"
            filter="true"
            :columnTypes="pluginRevo"
            class="grid-container-product-master"
            @afteredit="afterEdit"
            @beforeeditstart="beforeEditStart"
            @focusout="commitCell"
          ></v-grid>
        </b-row>
      </b-card-body>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody,
  BRow,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import VGrid from '@revolist/vue-datagrid';
import axios from '@axios'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import gql from 'graphql-tag'

export default {
  components: {
    BRow,
    BCard,
    BButton,
    BCardBody,

    CustomLoading,
    VGrid,
  },
  setup() {
    const isAddNewRowSidebarActive = ref(false)
    const source = ref([])
    const isLoading = ref(true)
    const refGridProductMaster = ref(null)
    const columns = ref([])
    const revoRowIndex = ref(null)
    const revoColName = ref(null)
    const modifiedData = ref([])

    const pluginRevo = { 'numeric': new NumberColumnType('0,0') }

    const vm = getCurrentInstance().proxy

    const clearRevoValues = () => {
      revoRowIndex.value = null
      revoColName.value = null
      modifiedData.value = []
    }

    const resetFilter = () => {
      refGridProductMaster.value.$el.getPlugins().then((plugins) => {
        plugins.forEach((p) => {
          if(p.clearFiltering) {
            p.clearFiltering()
          }
        })
      })
    }

    const afterEdit = (event) => {
      let copyModels = Object.assign({}, event.detail.models)
      if (Object.keys(copyModels).length === 0) return

      for (const prop in copyModels) {
        modifiedData.value.push(copyModels[prop])
      }
    }

    const beforeEditStart = (event) => {
      revoRowIndex.value = event.detail.rowIndex
      revoColName.value = event.detail.prop
    }

    const commitCell = async (event) => {
      const viewData = await refGridProductMaster.value.$el.getVisibleSource()
      let rowData = viewData[revoRowIndex.value]
      let oldValue = viewData[revoRowIndex.value][revoColName.value]
      let newValue = event.target.value
      if (oldValue != newValue) {
        viewData[revoRowIndex.value][revoColName.value] = newValue
        modifiedData.value.push(Object.assign({}, rowData))
        refGridProductMaster.value.$el.source = [...viewData]
      }
    }

    const saveData = async () => {
      if(modifiedData.value.length > 0) {
        vm.$swal({
          title: `¿Está seguro de que desea guardar los cambios?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si',
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          showLoaderOnConfirm: true,
          preConfirm() {
            return axios
              .post(`${process.env.VUE_APP_BASE_URL}/update_product`,{
                "data": modifiedData.value
              })
              .then(response => {
                if (response.status != 200) {
                  throw new Error(response.statusText)
                }
                return response
              })
              .catch(error => {
                vm.$swal.showValidationMessage(`Request failed:  ${error}`)
              })
          },
        }).then( async (result) => {
          if (result.value) {
            vm.$swal({
              icon: 'success',
              title: 'Datos guardados!',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              willClose: clearRevoValues
            })
          } 
        })
      }
    }

    // subscription 
    columns.value = [
      {
        "name": "BG",
        "prop": "BG",
        "size": 90,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "NART",
        "prop": "Material",
        "size": 170,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "SPGR",
        "prop": "SPGR",
        "size": 120,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "TIPO",
        "prop": "TIPO",
        "size": 150,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Descripcion",
        "prop": "Descripcion",
        "size": 400,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Portafolio",
        "prop": "Portafolio",
        "size": 130,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "BPU",
        "prop": "BPU",
        "size": 130,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Brand Category",
        "prop": "BrandCategory",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "Application Form",
        "prop": "ApplicationForm",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
      {
        "name": "EAN",
        "prop": "EAN",
        "size": 200,
        "autoSize": true,
        "sortable": true,
      },
    ]

    vm.$apollo.addSmartSubscription('getProductMaster', {
      query: gql`
        subscription {
          Maestro_productos {
            BG
            Material
            SPGR
            TIPO
            Descripcion
            Portafolio
            BPU
            BrandCategory
            ApplicationForm
            EAN
          }
        }
      `,
      result ({data}) {
        isLoading.value = false
        source.value = data.Maestro_productos

        // remove _typename
        for(let obj of source.value) {
          delete obj.__typename
        }
      },
    })

    // computed
    const isDisabledSaveData = computed(() => {
      return modifiedData.value.length <= 0
    })

    //onCreated
    clearRevoValues()

    return {
      isAddNewRowSidebarActive,
      source,
      columns,
      isLoading,
      refGridProductMaster,
      pluginRevo,

      resetFilter,
      afterEdit,
      beforeEditStart,
      commitCell,
      saveData,

      clearRevoValues,
      isDisabledSaveData,
    }
  },
}
</script>

<style lang="scss">
.grid-container-product-master {
  width: 100%;
  height: 85vh;
  font-family: "Montserrat", Helvetica, Arial, serif !important;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box
  }

  .header-rgRow {
    background: #0032a0;
    color: white;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: black;
  }
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
