<template>
  <b-tabs
    pills
  >
    <!-- : Information -->
    <b-tab active>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Informacion</span>
      </template>
      <product-master-information
        class="mt-2 pt-75"
      />
    </b-tab>

    <!-- Tab: Password -->
    <b-tab>
      <template #title>
        <feather-icon
          icon="DatabaseIcon"
          size="18"
          class="mr-50"
        />
        <span class="d-none d-sm-inline">Nart Sin Clasificar</span>
      </template>
      <product-classification
        class="mt-2 pt-75" 
      />
    </b-tab>

  </b-tabs>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert,
} from 'bootstrap-vue'
import ProductMasterInformation from './ProductMasterInformation.vue'
import ProductClassification from './product-classification/ProductClassification.vue'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BTab,
    BTabs,
    BCard,

    ProductMasterInformation,
    ProductClassification,
  },
  setup() {

  },
}
</script>

<style>

</style>
