<template>
  <b-card
    no-body
    border-variant="primary"
    class="p-2 bcard-data-gathering"
  >

    <b-card-title
      class="d-flex align-items-center justify-content-between"
    >
      <div>
        Product Master
      </div>
      
      <!-- Options Card -->
      <b-dropdown
        v-if="checkPermissionLoadData"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-primary"
        size="sm"
        right
        class="dropdown-icon-wrapper"
      >
        <template #button-content>
          <feather-icon icon="MoreVerticalIcon" size="20"/>
        </template>

        <div
          v-if="showAddDeleteOption"
        >
          <b-dropdown-item 
            @click="addConfirmation"
          >
            <feather-icon icon="UploadIcon" size="10"/>
            <span class="ml-1">Agregar</span>
          </b-dropdown-item>

          <!-- <b-dropdown-item
            v-if="$can('delete', 'Admin')"
            @click="deleteConfirmation"
          >
            <feather-icon icon="XCircleIcon" size="10"/>
            <span class="ml-1">Borrar</span>
          </b-dropdown-item> -->
        </div>

        <b-dropdown-item 
          @click="downloadConfirmation"
        >
          <feather-icon icon="DownloadIcon" size="10"/>
          <span class="ml-1">Plantilla</span>
        </b-dropdown-item>
      </b-dropdown>
    </b-card-title>

    <div 
      class="bcard-data-gathering-body"
    >
      <div
        v-if="showUploadFile"
      >
        <div
          v-if="showUploadFileDialog"
        >
          <div 
            v-if="showUploadFileProgress" 
            class="text-center rounded show-upload-file-progress"
          >
            <feather-icon
              icon="UploadCloudIcon"
              size="30"
            />
            <div class="mb-2">Subiendo archivo... {{ barValue + '%' }}</div>
            <b-progress
              min="1"
              max="100"
              :value="barValue"
              variant="success"
              height="10px"
              class="mx-n4"
            ></b-progress>
          </div>
          <div
            v-else
            :id="`upload-file-confirm-product-classification`"
            ref="upload-file-dialog"
            tabindex="-1"
            role="dialog"
            aria-modal="false"
            aria-labelledby="form-confirm-label"
            class="text-center"
          >
            <b-img
              :src="require('@/assets/images/icons/xls.png')"
              width="120px"
              alt="excel file image"
            />
            <p><strong>{{inputFile.name}}</strong></p>
            <p><strong>¿Desea subir el archivo?</strong></p>
            <div>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger" 
                class="mr-3" @click="onCancel"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-success"
                @click="onOK"
              >
                OK
              </b-button>
            </div>
          </div>
        </div>

        <div
          v-else
        >
          <b-form-file
            ref="refInputEl"
            v-model="inputFile"
            :accept="allowedExtensions"
            class="my-1 custom-input"
            @input="onInputFile"
          >
            <template slot="placeholder">
              <div
                class="d-flex flex-column align-items-center"
              >
                <feather-icon
                  icon="UploadIcon"
                  size="30"
                />

                <label>Arraste y Suelte un archivo</label>

                <label>O</label>

                <b-button
                  pill
                  variant="primary"
                  size="sm"
                >
                  Busque un archivo
                </b-button>
              </div>
            </template>

            <template slot="drop-placeholder">
              <div
                class="d-flex flex-column align-items-center"
              >
                <feather-icon
                  icon="UploadIcon"
                  size="30"
                />
                
                <h5>Suelte para subir</h5>
              </div>
            </template>

          </b-form-file>
        </div>
      </div>
      <div
        v-else
      >
        <div
          class="d-flex flex-column align-items-center"
        >
          <b-img
            :src="require('@/assets/images/icons/xls.png')"
            width="150px"
            alt="excel file image"
          />

          <b-button
            class="mt-2"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :to="{ name: 'product-master'}"
          >
            Ver Detalle
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BCardGroup, BCard, BCardText, BCardTitle, BCardFooter, BCardBody, BImg, BFormFile, 
  BButton, BProgress, BAlert, BDropdown, BDropdownItem, BDropdownItemButton, VBModal,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BImg,
    BFormFile,
    BButton,
    BProgress,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
  },
  directives: {
    'b-modal': VBModal,
    'height-fade': heightFade,
    Ripple,
  },
  setup(props, context) {
    const inputFile = ref([])
    const refInputEl = ref(null)
    const barValue = ref(1)
    const showUploadFile = ref(true)
    const showUploadFileDialog = ref(false)
    const showUploadFileProgress = ref(false)
    const allowedExtensions = ref(".xlsx, .xls")
    const showAddDeleteOption = ref(false)
    const inputErrorsData = ref(null)
    const hasData = ref(false)

    const vm = getCurrentInstance().proxy

    // computed
    const checkPermissionLoadData = computed(() => {
      return vm.$can('read', 'Admin')
    })

    // methods
    const onInputFile = () => {
      inputFile.value = refInputEl.value.files[0]

      // validate file extension
      const allowedExtensionsRxg = /(\.xls|\.xlsx)$/i;

      if (!allowedExtensionsRxg.exec(inputFile.value.name)) {
        inputFile.value = []
        return
      } 

      showUploadFileDialog.value = true
    }

    const onCancel = () => {
      showUploadFileDialog.value = false
      inputFile.value = []
    }

    const showErrorDetails = () => {
      if (inputErrorsData.value.details == null || inputErrorsData.value.details == undefined || inputErrorsData.value.details.length == 0) return
      context.emit('set-errors-data', inputErrorsData.value.details)
      vm.$bvModal.show('data-gathering-error-modal')
    }

    const onOK = async () => {
      try {
        inputErrorsData.value = null
        barValue.value = 1
        showUploadFileProgress.value = true

        let formData = new FormData();
        formData.append('excel_file', inputFile.value);
  
        const response = await axios
        .post(`${process.env.VUE_APP_BASE_URL}/upload_excel_product`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function( progressEvent ) {
              barValue.value = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
            }
          }
        )

        hasData.value = true
      } catch(error) {
        inputErrorsData.value = error.response.data

        vm.$swal({
          title: 'Error!',
          text: error.response.data.error,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
          allowOutsideClick: false,
          willClose: showErrorDetails
        })
      } finally {
        showUploadFile.value = true
        inputFile.value = []
        showUploadFileDialog.value = false
        showUploadFileProgress.value = false
      }
    }

    const addConfirmation = () => {
      vm.$swal({
        title: '¿Está seguro de que desea agregar otro archivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showUploadFile.value = true
        } 
      })
    }

    const deleteConfirmation = () => {
      vm.$swal({
        title: '¿Está seguro de que desea borrar el archivo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then( async (result) => {
        if (result.value) {
          try {
            const month = dateSelected.value.split('-')[0]
            const year = dateSelected.value.split('-')[1]

            await axios
            .post(`${process.env.VUE_APP_BASE_URL}/del_data`,
              {
                year,
                month,
                area_id: areaData.value.area_id
              }
            )

            vm.$swal({
              title: 'Datos borrados!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
              willClose: () => showUploadFile.value = true
            }) 

          } catch(e) {
            vm.$swal({
              title: 'Error!',
              text: 'No se pudo borrar los datos',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            console.log(e)
          }
        } 
      })
    }

    const downloadConfirmation = () => {
      vm.$swal({
        title: `¿Está seguro de que desea descargar la plantilla?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then( async (result) => {
        if (result.value) {
          try {
            const response = await axios
            .post(`${process.env.VUE_APP_BASE_URL}/productos_sin_clasificar`, {},
              {
                responseType: 'blob'
              }
            )
            
            const filename = response.headers.filename
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', filename)
            document.body.appendChild(link)
            link.click()
            link.remove()
          } catch(e) {
            vm.$swal({
              title: 'Error!',
              text: 'No se pudo descargar la plantilla',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            console.log(e)
          }
        } 
      })
    }

    return {
      inputFile,
      refInputEl,
      barValue,
      showUploadFile,
      showUploadFileDialog,
      showUploadFileProgress,
      allowedExtensions,
      showAddDeleteOption,

      onInputFile,
      onCancel,
      onOK,
      addConfirmation,
      deleteConfirmation,
      downloadConfirmation,

      //computed
      checkPermissionLoadData,
    }
  }
}
</script>

<style lang="scss" scoped>
.bcard-data-gathering {
  height: 350px;
}

.bcard-data-gathering-body {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bcard-data-gathering-alert {
  font-size: 10px;
}

.show-upload-file-progress {
  width: 110px;
}
</style>

<style lang="scss">
.custom-input {
  height: 200px;
  border-style: dashed;
  border-width: 1px;
  .custom-file-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-file-input ~ .custom-file-label::after {
    display: none;
    content: "buscar";
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
