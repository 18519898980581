import { render, staticRenderFns } from "./ProductMaster.vue?vue&type=template&id=251c3225&"
import script from "./ProductMaster.vue?vue&type=script&lang=js&"
export * from "./ProductMaster.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports